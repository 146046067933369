import React, { useContext, useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { appContext } from "../AarmaContext";
import axios from "axios";
import Pagination from "../Common/Pagination";

function Dashboard() {
  const { account, settings, copyaddress, formatAddress, stakcontract, getData, formatDateTime, ethers, dbuser, MemebersData, Members } = useContext(appContext);
  const [data, setdata] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [rewardtbl, setrewardtbl] = useState([]);

  const getdata = async () => {
    if (!account) {
      return;
    }
    if (!stakcontract) {
      return;
    }
    var rewarddata = await stakcontract.userdetails(account);
    console.log("elelelelellelel", rewarddata, account);
    var fdata = []
    // for (let index = 0; index < rewarddata.length; index++) {
    //   const element = rewarddata[index];
    var rtbl = {
      id: rewarddata._id,
      address: rewarddata._user,
      token: Number(ethers.utils.formatUnits(rewarddata._token, 18)).toFixed(2),
      usdt: Number(ethers.utils.formatUnits(rewarddata._usdt, 18)).toFixed(2),
      discusdt: Number(ethers.utils.formatUnits(rewarddata._discusdt, 18)).toFixed(2),
      phase: Number(ethers.utils.formatUnits(rewarddata._phase, 18)).toFixed(0),
      date: formatDateTime(rewarddata._date),
    }
    fdata.push(rtbl);
    // }
    setrewardtbl(fdata);
    console.log("setrewardtbl---------------->", fdata);
  }
  useEffect(() => {
    // getsmcs();
    getdata();
  }, [dbuser, account, stakcontract]);
  return (
    <>
      <Header />
      <section className="counter_section pb-120 pt-120 bg-blue">
        <div className="container">
          <div className="row">
            <div className="counter_section__area d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap gap-5">
              <div className="counter_section__item">
                <div className="hero_area__countdown counters d-flex align-items-center justify-content-center">
                  <span className="fs-one fw_500">$</span>
                  <span className="odometer hero_area__countdown-number fs-one fw_500" data-odometer-final="498.7" />
                  <span className="fs-one fw_500">{MemebersData.usdt}</span>
                </div>
                <h4 className="text-center">Total Buy ($)</h4>
              </div>
              {/* <span className="v-line lgx mb-20 pb-6 d-none d-md-block" />
              <div className="counter_section__item">
                <div className="hero_area__countdown counters d-flex align-items-center justify-content-center">
                  <span className="odometer hero_area__countdown-number fs-one fw_500" data-odometer-final="245.91" />
                  <span className="fs-one fw_500">K</span>
                </div>
                <h4 className="text-center">Total Participants</h4>
              </div> */}
              <span className="v-line lgx mb-20 pb-6 d-none d-md-block" />
              <div className="counter_section__item">
                <div className="hero_area__countdown counters d-flex align-items-center justify-content-center">
                  <span className="odometer hero_area__countdown-number fs-one fw_500" data-odometer-final="1700.84" />
                  <span className="fs-one fw_500">{MemebersData.token} {process.env.REACT_APP_TICKER}</span>
                </div>
                <h4 className="text-center">Token Balance</h4>
              </div>
              <span className="v-line lgx mb-20 pb-6 d-none d-xl-block" />
              <div className="counter_section__item">
                <div className="hero_area__countdown counters d-flex align-items-center justify-content-center">
                  <span className="odometer hero_area__countdown-number fs-one fw_500" data-odometer-final={12} />
                </div>
                {/* <h4 className="text-center">Number of Projects</h4> */}
                <button
                  type="button"
                  className="cmn-btn px-3 px-sm-5 px-md-6 py-3 py-sm-2 d-flex align-items-center gap-1 text-white"
                  disabled
                >
                  <span className="p7-color fw-semibold d-none d-sm-block text-white">
                    Stake
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="discover_web3 pools_tables pt-120 pb-120 bg-black-new vh-100">
        <h1 className="d-flex justify-content-center"> Dashboard</h1>
        <div className="container d-flex align-items-center pt-5">
          <div className="col-12 pt-5">
            <div className="apex_section__tablearea br2 rounded-20 bg1-color overflow-auto mt-5 mt-md-6">
              <div className="apex_section__tablechart p-5 p-md-6 d-flex gap-4 align-items-center justify-content-between bg_blur">
                <table style={{ border: "none" }}>

                  <thead className="iq-bg">
                    <tr className="text-center " style={{ border: "none" }}>
                      <th className="fs-ten" style={{ border: "none" }}>#</th>
                      <th className="fs-ten" style={{ border: "none" }}>Date</th>
                      <th className="fs-ten" style={{ border: "none" }}>Phase</th>
                      <th className="fs-ten" style={{ border: "none" }}>Package</th>
                      <th className="fs-ten" style={{ border: "none" }}>Discount USDT</th>
                      <th className="fs-ten" style={{ border: "none" }}>LMC Token</th>
                    </tr>
                  </thead>
                  {!isLoading ? (
                    !rewardtbl.length ? (
                      <tr className="text-center">
                        <td className="text-center" colSpan={7}>
                          <span className="w-100">No data found</span>
                        </td>
                      </tr>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <tbody style={{ border: "none" }}>
                    {/* {rewardtbl.map((ele, i) => {
                      return ( */}
                    <tr className="text-center">
                      <td>
                        {1}
                      </td>
                      <td>
                        {Members !== null ? Members.date : 0}
                      </td>
                      <td>
                        {Number(Members !== null ? Members.phase : 0) + 1}
                      </td>
                      <td>
                        {Members !== null ? Members.usdt : 0}
                      </td>
                      <td>
                        ${Members !== null ? Members.discusdt : 0}
                      </td>
                      <td>
                        ${Members !== null ? Members.token : 0}
                      </td>

                    </tr>
                    {/* );
                    })} */}

                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pages={pages}
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Dashboard;
