import React from 'react'

export default function Phase() {
    return (
        <>
            <section className="rewards_faq pt-120 pb-120 position-relative bg-white">
                <div className="container">
                    <div className="row align-items-center justify-content-center gy-8">
                        <div className="col-xl-5">
                            <div className="rewards_faq__content text-black">
                                <h2 className="mb-5 mb-md-6 wow fadeInUp  text-black">LMC – Social currency for LikeME Users
                                </h2>
                                <p className="roboto mb-8 mb-md-10 wow fadeInUp  text-black">LikeME uses LMC as its native currency. It’s a crypto asset that can be traded, transferred or exchanged in live markets. Users can acquire it while using the LikeME App or buy it from crypto trading platforms. </p>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="rewards_faq__thumb">
                                <img src="assets/newimg/likeme_coin_presale.png" className="max-un leftright-animation w-100" alt="Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img class="rewards_faq__light position-absolute top-0 start-0" src="assets/images/light-faq.png" alt="Icon"> */}
            </section>
        </>
    )
}
