import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - Date.now();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((difference / 1000 / 60) % 60),
        Seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const calculatedTimeLeft = calculateTimeLeft();
      setTimeLeft(calculatedTimeLeft);
      setLoading(false);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const timerComponents = Object.keys(timeLeft).map(interval => {
    if (!timeLeft[interval]) {
      return null;
    }

    return (
      <div key={interval} className='text-center'>
        <span className='mx-2'>{timeLeft[interval]}</span>
        <br />
        <span className='mx-2'>{interval}</span>
      </div>
    );
  });

  return (
    <div className='d-flex flex-wrap justify-content-around'>
      {loading ? <span>Loading...</span> : (timerComponents.length ? timerComponents : <span>Time's up!</span>)}
    </div>
  );
};

export default CountdownTimer;
