import React from "react";

function Footer() {
  return (
    <footer className="footer  bg5-color">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
          <div className="col-sm-10 col-xxl-6 d-flex justify-content-center">
            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
              <div className="copy-right area">
                <span className="p4-color roboto text-center text-md-start">
                © 2024 LikeME | All Rights Reserved.
                 
            
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
              <a href="https://www.facebook.com/people/LikeMe-Coin/61557800742474" target="_blank">
                <i className="fa fa-facebook p4-color fs-four fw-normal px-4 py-2" />
              </a>
              <a target="_blank"  href="https://www.linkedin.com/in/likemecoin/recent-activity/all/">
                <i className="fa fa-linkedin p4-color fs-four fw-normal px-3 py-2" />
              </a>
              <a target="_blank" href="https://www.instagram.com/likemecoin">
                <i className="fa fa-instagram p4-color fs-four fw-normal px-3 py-2" />
              </a>
              <a target="_blank" href="https://x.com/LikeMeCoin">
                <i className="fa fa-twitter p4-color fs-four fw-normal px-3 py-2" />
              </a>
              <a target="_blank" href="https://t.me/likeme_en">
                <i className="fa fa-telegram p4-color fs-four fw-normal px-3 py-2" />
              </a>
              <a target="_blank"  href="https://www.youtube.com/channel/UCZJNs8cQQR46h555wMEG4FQ">
                <i className="fa fa-youtube p4-color fs-four fw-normal px-3 py-2" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
