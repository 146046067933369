import React, { useState, useContext } from "react";
import { appContext } from "../AarmaContext";
import { formatAddress } from "./Formate";
import CopyBtn from "./CopyBtn";
import { Link } from "react-router-dom";

function Header() {
  const { connectedAddress, isConnected, account, connectWallet, disconnect } = useContext(appContext);

  const handleConnectWallet = (e) => {
    connectWallet(e.target.value);
    console.log(connectWallet(e.target.value));
  };

  const handleDisconnectWallet = () => {
    disconnect();
  };
  const [open, setOpen] = useState(false);
  return (
    <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
      <div className="navbar_mainhead header-fixed w-100 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 ">
              <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 work ready d-flex justify-content-between">
                <Link to="/">
                  <img src="assets/images/likeme_logo.png" style={{ width: "200px" }} alt="" />
                </Link>
                <div className={open ? "collapse navbar-collapse show justify-content-between" : "collapse navbar-collapse justify-content-between"} id="navbar-content">
                  <div className="d-lg-none d-block mt-4">
                    {account ? (
                      <>
                        <div className="header-section__modalstyle">
                          <div className="d-flex">
                            <button
                              type="button"
                              className="cmn-btn d-flex align-items-center px-2 py-0 text-white"
                            >
                              {formatAddress(account)}&nbsp;
                              <CopyBtn text={account} />
                            </button>
                            <Link
                              to="/dashboard"
                              className="btn btn-secondary rounded-pill py-2 px-4 ts-3 m-2"
                            >
                              <i className="fa fa-regular fa-user"></i>
                            </Link>
                            <Link
                              className="btn btn-secondary rounded-pill py-2 px-3 ts-3 m-2"
                              to="/"
                            >
                              <i className="fa fa-home"></i>
                            </Link>
                            <Link
                              className="btn btn-secondary rounded-pill py-2 px-3 ts-3 m-2"
                              to="/"
                              onClick={handleDisconnectWallet}
                            >
                              <i className="fa fa-sign-out fa-user"></i>
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="header-section__modalstyle">
                        <button type="button" className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1" onClick={handleConnectWallet}>
                          {/* <span className="p7-color fw-semibold d-none d-sm-block">Connect</span> */}
                          Connect Wallet
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-lg-block d-none">
                  <div className="right-area custom-pos position-relative d-flex justify-content-between gap-0 gap-lg-2 align-items-center">
                    {/* Button trigger modal */}
                    {account ? (
                      <>
                        <div className="header-section__modalstyle">
                          <div className="d-flex justify-content-center">
                            <button
                              type="button"
                              className="cmn-btn d-flex align-items-center px-2 py-0 text-white"
                            >
                              {formatAddress(account)}&nbsp;
                              <CopyBtn text={account} />
                            </button>
                            <Link
                              to="/dashboard"
                              className="btn btn-secondary rounded-pill py-2 px-4 ts-3 m-2"
                            >
                              <i className="fa fa-regular fa-user"></i>
                            </Link>
                            <Link
                              className="btn btn-secondary rounded-pill py-2 px-3 ts-3 m-2"
                              to="/"
                            >
                              <i className="fa fa-home"></i>
                            </Link>
                            <Link
                              className="btn btn-secondary rounded-pill py-2 px-3 ts-3 m-2"
                              to="/"
                              onClick={handleDisconnectWallet}
                            >
                              <i className="fa fa-sign-out fa-user"></i>
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="header-section__modalstyle">
                        <button type="button" className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1" onClick={handleConnectWallet}>
                          {/* <span className="p7-color fw-semibold d-none d-sm-block">Connect</span> */}
                          Connect Wallet
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <button className={open ? "navbar-toggler open" : "navbar-toggler"} onClick={() => setOpen(!open)} type="button" data-bs-toggle="collapse" aria-label="Navbar Toggler" data-bs-target="#navbar-content" aria-expanded="true" id="nav-icon3">
                  <span /><span /><span /><span />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
