import React, { createContext, useState, useEffect } from "react";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";

export const appContext = createContext();
const projectId = "c9fb67051cfd714f16c3d23e9df4d518";
// const mainnet = [{
//   chainId: 97,
//   name: "BSC Testnet",
//   currency: "BNB",
//   explorerUrl: "https://testnet.bscscan.com/",
//   rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
// }];
const mainnet = [{
  chainId: 56,
  name: "BSC Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org",
}];

// const Holesky = {
//   chainId: 17000,
//   name: "Holesky",
//   currency: "ETH",
//   explorerUrl: "https://holesky.etherscan.io/",
//   rpcUrl: "https://rpc.holesky.ethpandaops.io",
// };
const metadata = {
  name: "LikeME",
  description: "LikeME",
  url: "https://mywebsite.com",
  icons: ["https://avatars.mywebsite.com/"],
};

const ethersConfig = defaultConfig({
  metadata,

  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true,
  rpcUrl: "...",
});
createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: mainnet[0].chainId,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "...", // used for the Coinbase SDK
  }),
  chains: mainnet,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

export default function MyContext({ children }) {
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();

  const [connectedAddress, setconnectedAddress] = useState();
  var { address, chainId, isConnected } = useWeb3ModalAccount();
  // address = "0x30049217809b91b3e3653c447a05d47515ac5174";
  const { disconnect } = useDisconnect();
  const [balance, setBalance] = useState(0);
  const [USDTbalance, setUSDTBalance] = useState(0);
  const [settings, setsettings] = useState([]);
  const [rate, setrate] = useState(540);
  const [esigner, setesigner] = useState(null);
  const [tokenrate, settokenrate] = useState(0);
  const [contdata, setcontdata] = useState(null);
  const [smuser, setsmuser] = useState(null);
  const [account, setaccount] = useState(address);
  const [stakcontract, setstakcontract] = useState(null);
  const [Members, setMembers] = useState(null);
  const [MemebersData, setMemebersData] = useState([]);
  const [getCurrentPhase, setgetCurrentPhase] = useState(0);
  const [getCurrentPrices, setgetCurrentPrice] = useState(0);
  const [getsaleEndTimestamp, setgetsaleEndTimestamp] = useState(0);
  const [gettokensSoldInPhasedata, setgettokensSoldInPhasedata] = useState(0);
  const [getTokenAmountsedata, setgetTokenAmountsedata] = useState(0);
  const [currentPhaseDays, setcurrentPhaseDays] = useState(0);

  const connectWallet = () => {
    try {
      open();
    } catch (err) {
      console.log("error : ", err);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };
  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };

  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };
  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        console.log("setting", res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };

  useEffect(() => {
    setaccount(address)
  }, [address]);
  const getSigner = async () => {
    if (!isConnected) return;

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    setesigner(signer)
  }
  function daysUntilToday(startDate) {
    const start = new Date(startDate);
    const today = new Date();

    const diffTime = today - start;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }
  const getsmcs = async () => {
    if (!isConnected || !account || !settings.presale || esigner === null) return;

    let maincontract = new ethers.Contract(
      settings.presale,
      JSON.parse(settings.presale_abi),
      esigner
    );
      console.log("pressale", settings.presale);
    setstakcontract(maincontract);
    // console.log("getrate", balance);
    // const getCurrentPhasedata = await maincontract.getCurrentPhase();
    const getCurrentPhasedata = 4;
    setgetCurrentPhase(Number(4));
    // const getCurrentPhasedata = await maincontract.getCurrentPhase();
    // setgetCurrentPhase(Number(getCurrentPhasedata));


    const saleEndTimestampdata = await maincontract.endsale();
    setgetsaleEndTimestamp(saleEndTimestampdata);

    const tokensSoldInPhasedata = await maincontract.tokensSoldInPhase();
    const endsaledate = await maincontract.startsale();
    // const phasedates = await maincontract.saleEndTimestamp(getCurrentPhasedata);
    const phasedates = await maincontract.endsale();
    setgettokensSoldInPhasedata(Number(ethers.utils.formatUnits(tokensSoldInPhasedata, 18)).toFixed(2));

    // const TokenAmountsdata = await maincontract.phaseTokenAmounts(getCurrentPhase);
    // setgetTokenAmountsedata(Number(ethers.utils.formatUnits(TokenAmountsdata, 18)).toFixed(2));

    // console.log("getCurrentPrice", getCurrentPrice);
    const getCurrentPricedata = await maincontract.price();
    setgetCurrentPrice(Number(ethers.utils.formatUnits(getCurrentPricedata, 18)).toFixed(2));
    var stardate = null
    console.log("getCurrentPhasedatagetCurrentPhasedata", Number(getCurrentPhasedata));
    if (Number(getCurrentPhasedata) === 0) {
      stardate = endsaledate
    } else {
      stardate = phasedates
    }
    var dasyys = daysUntilToday(Number(stardate) * 1000);
    setcurrentPhaseDays(dasyys)
    const userdata = await maincontract.userdetails(address);
    var rtbl = {
      id: userdata._id,
      address: userdata._user,
      token: Number(ethers.utils.formatUnits(userdata._token, 18)).toFixed(2),
      usdt: Number(ethers.utils.formatUnits(userdata._usdt, 18)).toFixed(2),
      discusdt: Number(ethers.utils.formatUnits(userdata._discusdt, 18)).toFixed(2),
      phase: Number(ethers.utils.formatUnits(userdata._phase, 18)).toFixed(0),
      date: formatDateTime(Number(userdata._date)),
    }
    setMembers(rtbl);
    const MemData = {
      "usdt": (Number(ethers.utils.formatUnits(userdata._usdt, 18)).toFixed(2)),
      "token": (Number(ethers.utils.formatUnits(userdata._token, 18)).toFixed(2)),
    }
    console.log("setMemebersData", MemebersData);
    setMemebersData(MemData);
  };

  function formatDateTime(timestamp) {
    if (timestamp === 0) {
      return '-'
    }
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    // Get the date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Get the time components
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Construct the formatted date-time string
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }
  useEffect(() => {
    if (address) {
      setconnectedAddress(address);
    } else {
      setconnectedAddress("");
    }
  }, [address]);

  useEffect(() => {
    getsmcs();
    // }, [account, address,library, settings]);
  }, [isConnected, address, account, walletProvider, settings, esigner, getsaleEndTimestamp]);
  useEffect(() => {
    getSigner();
  }, [isConnected, walletProvider]);
  useEffect(() => {
    getSetting()
  }, []);

  return (
    <><Toaster
      position="top-left"
      toastOptions={{
        // Define default options
        className: "",
        duration: 3000,
        style: {
          background: "#ffa518",
          color: "#fff",
        },
        success: {
          duration: 3000,
          theme: {
            primary: "green",
            secondary: "black",
          },
        },
      }} /><appContext.Provider
        value={{
          settings: settings,
          library: ethers,
          balance,
          esigner,
          address,
          tokenrate,
          smuser,
          // account: ovrAddress ? ovrAddress : address ? address.toLowerCase() : address,
          account: account ? account.toLowerCase() : account,
          setaccount,
          formatAddress,
          toastError,
          toastSuccess,
          toastInfo,
          getsmcs,
          formatDateTime,
          daysUntilToday,
          getCurrentPhase,
          getCurrentPrices,
          getsaleEndTimestamp,
          gettokensSoldInPhasedata,
          getTokenAmountsedata,
          MemebersData, USDTbalance, currentPhaseDays,
          ethers, Members,
          rate,
          copyaddress, connectWallet,
          contdata, disconnect,
          stakcontract,
        }}
      >
        {children}
      </appContext.Provider></>
  );
}
