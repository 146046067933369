import React, { useContext, useEffect, useState } from 'react'
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { appContext } from '../AarmaContext';
import axios from 'axios';
import moment from 'moment-timezone';
import CountdownTimer from './CountdownTimer';
import { useNavigate } from 'react-router-dom';

export default function Preesale() {
    const { account, stakcontract, settings, connectWallet, library, esigner, toastSuccess, toastError, getsmcs, ethers, formatAddress, contdata, daysUntilToday, getCurrentPhase, getCurrentPrices, getsaleEndTimestamp, gettokensSoldInPhasedata, currentPhaseDays, getTokenAmountsedata } = useContext(appContext);
    const [tokenchoice, settokenchoice] = useState(0);
    const [usdt, setusdt] = useState(0);
    const [token, settoken] = useState(0);
    const [trdone, settrdone] = useState(false);
    const [hash, sethash] = useState("");
    const [tokennew, settokennew] = useState(0);
    const [percent, setpersent] = useState(0);
    const navigate = useNavigate();

    const onDeposit = async () => {
        sethash(null)
        settrdone(true);

        sethash("");
        if (!account) {
            connectWallet();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        let token_contract = new library.Contract(
            settings.token,
            JSON.parse(settings.token_abi),
            esigner
        );
        const allowance = await token_contract.allowance(
            account,
            settings.presale
        );

        async function main() {

            try {
                var stk = await stakcontract.Buytoken(selectedOption);

                const receipt = await stk.wait();
                sethash(receipt.transactionHash);
                getsmcs()
                toastSuccess("Transaction done");
                // toastSuccess("You will receive Token within 5 minute");
                // sethash(randomHash);
                settrdone(false);
                setSelectedOption('');
                navigate("/dashboard");

            } catch (error) {
                toastError("Transaction declined");
                settrdone(false);
                return;
            }

        }
        if (Number(ethers.utils.formatEther(allowance)) === 0) {
            // if (false) {
            try {
                var approv = await token_contract.approve(
                    settings.presale,
                    ethers.utils.parseEther("100000000")
                );
                const receipt = await approv.wait();
                console.log(receipt);
                toastSuccess("Token Approved");
                main();
            } catch (error) {
                console.log("error", error);
                toastError("Token not approved");
                settrdone(false);
                return;
            }

        } else {
            main();
        }

    };
    useEffect(() => {
        if (contdata === null) {
            return
        }
        console.log("Number(tokennew / contdata.current_price)", Number(tokennew), contdata.current_price);
        if (tokenchoice === 0) {
            settoken(0)
            setusdt(Number(tokennew * contdata.current_price).toFixed(4))
        } else {
            setusdt(0)
        }
    }, [tokenchoice, tokennew, contdata])
    const [selectedOption, setSelectedOption] = useState('');
    const [discountedAmount, setDiscountedAmount] = useState(0);
    const [payableAmount, setPayableAmount] = useState(0);

    // Handler function to calculate discounted and payable amounts
    const handleOptionChange = (event) => {
        const selectedValue = parseFloat(event.target.value);
        const discount = 0.5; // 50% discount
        const calculatedDiscount = selectedValue * discount;

        setDiscountedAmount(calculatedDiscount.toFixed(2)); // Discounted amount
        setPayableAmount((selectedValue - calculatedDiscount).toFixed(2)); // Payable amount
        setSelectedOption(event.target.value);
    };

    const getPackageValue = (selectedOption) => {
        switch (selectedOption) {
            case '0':
                return 99;
            case '1':
                return 599;
            case '2':
                return 1199;
            case '3':
                return 5599;
            case '4':
                return 11199;
            default:
                return "";
        }
    };

    const packageValue = getPackageValue(selectedOption);
    const [contractData, setContractData] = useState('');
    const [contractDataToken, setContractDataToken] = useState('');

    const handleOptionChangedata = async (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        console.log("value", value);
        try {

            // Call a function from your smart contract based on the selected option
            var data = await stakcontract.calcpackage(value);

            console.log('Contract data:', data);
            // Update the state with the data from the contract
            setContractData(Number(ethers.utils.formatUnits(data[0], 18)));
            setContractDataToken(Number(ethers.utils.formatUnits(data[1], 18)));
        } catch (error) {
            console.error('Error reading contract data:', error);
        }
    };


    useEffect(() => {
        const persentage = (gettokensSoldInPhasedata * 100) / getTokenAmountsedata;
        setpersent(persentage);
    }, [gettokensSoldInPhasedata, getTokenAmountsedata])

    return (
        <>
            <section className="earn_crypto pt-120 pb-120" id='Preesale'>
                <div className="container d-flex justify-content-center align-items-center">
                    <div className="row gy-5 gy-md-6">
                        <div className="col-xl-12 d-flex justify-content-center align-items-center">
                            <div className="earn_crypto__card one bg7-color br2 rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                                <div className="d-flex align-items-start justify-content-center flex-wrap gap-3 mb-8 mb-sm-10 mb-md-5">
                                    <div>
                                        <h2 className="mb-3 text-center ">LMC Presale Staking</h2>
                                        <h4 className="mb-1 text-center ">Phase-{getCurrentPhase + 1} Day-{currentPhaseDays}</h4>
                                        <span className='d-flex justify-content-center'><CountdownTimer targetDate={getsaleEndTimestamp * 1000} />
                                        </span>
                                    </div>
                                </div>
                                {/* <ProgressBar
                                    percent={percent}
                                    filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                                >
                                    <Step transition="scale">
                                        {({ accomplished }) => (
                                            <span
                                                className="progress_bar_circle"
                                                style={{
                                                    filter: `grayscale(${accomplished ? 0 : 30}%)`,
                                                }}
                                            ></span>
                                        )}
                                    </Step>
                                    <Step transition="scale">
                                        {({ accomplished }) => (
                                            <span
                                                className="progress_bar_circle"
                                                style={{
                                                    filter: `grayscale(${accomplished ? 0 : 30}%)`,
                                                }}
                                            ></span>
                                        )}
                                    </Step>
                                </ProgressBar> */}
                                {/* <div className="d-flex justify-content-between my-1">
                                    <span>{gettokensSoldInPhasedata}</span>
                                    <span>{getTokenAmountsedata}</span>
                                </div> */}
                                {/* <hr />
                                 <div className="d-flex justify-content-between my-1">
                                    <span>Total Sell</span>
                                    <h5>600000</h5>
                                </div>
                                <hr /> */}
                                <div className="earn_crypto__range slider">
                                    <div className="slide container">
                                        <div className="range-style overflow-hidden position-relative w-100">
                                            <form>

                                                {/* <div className="buy_crypto__form area-group mb-5 mb-md-6">

                                                    <label className="my-5">LMC Amount</label>
                                                    {tokenchoice === 0 ?
                                                        <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                            <input type="text" placeholder="Enter Amount" value={usdt} onChange={(e) => setusdt(e.target.value)} />
                                                        </div> :
                                                        <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                            <input type="text" placeholder="Enter Amount" value={token} onChange={(e) => settoken(e.target.value)} />
                                                        </div>}
                                                    <div className="d-flex align-items-center br2 p-1 rounded-4 bg1-color">
                                                        <input type="text" placeholder="Enter Amount" value={tokennew} onChange={(e) => settokennew(e.target.value)} />
                                                    </div>

                                                </div> */}
                                                <div className="buy_crypto__formarea-group mb-5 mb-md-6">
                                                    <label className="my-5">LMC Package</label>
                                                    <div className="mb-2 br2 p-1 rounded-4 bg1-color">
                                                        <div className="text-end">
                                                            <div className="apex_section__slider-selector markets_section__rcard-selector">
                                                                <div className="f-group">
                                                                    <select id="select5" className="f-control f-dropdown" onChange={handleOptionChangedata} value={selectedOption}>
                                                                        <option value="Select Package" selected data-image="assets/images/icon/tableicon11.png">
                                                                            Select Package</option>
                                                                        <option value={0} selected data-image="assets/images/icon/tableicon11.png">
                                                                            $99</option>
                                                                        <option value={1} selected data-image="assets/images/icon/tableicon11.png">
                                                                            $599</option>
                                                                        <option value={2} data-image="assets/images/icon/currencyCategory5333a.png">
                                                                            $1199</option>
                                                                        <option value={3} data-image="assets/images/icon/currencyCategory6bd4a.png">
                                                                            $5599</option>
                                                                        <option value={4} data-image="assets/images/icon/currencyCategory71949.png">
                                                                            $11199</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <span>Rate</span>
                                                        <span className="p1-color">{getCurrentPrices} USDT</span>
                                                    </div>
                                                </div>


                                                <div className="d-flex gap-3 justify-content-center flex-wrap mb-5 mb-md-6">
                                                    <div>
                                                        {selectedOption && (
                                                            <div>
                                                                <div className="d-flex justify-content-between align-items-end gap-2">
                                                                    {/* <p>Discounted amount (50% off): ${discountedAmount}</p> */}
                                                                    <h1 className="fw_500"><p>Payable amount: </p>${contractData}<sup><del>${packageValue}</del></sup></h1>
                                                                    {/* <h3 className="fw_500">{contractDataToken} LMC</h3> */}
                                                                </div>
                                                            </div>
                                                        )}



                                                        {/* <h1 className="fw_500">{tokenchoice === 0 ? usdt : token} </h1> */}
                                                        {/* <span className="mb-2">{tokenchoice === 0 ? "USDT" : "ARMA"}</span> */}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {trdone ? (
                                    <div className='text-center'>
                                        <img
                                            className="border-0"
                                            src="/assets/images/loader.gif"
                                            alt="loading"
                                            height={100}
                                            width={100}
                                        />
                                    </div>
                                ) : (
                                    <a type='button' className="cmn-btn py-3 px-5 px-md-6 d-block text-white" onClick={() => onDeposit()}>
                                        Buy LMC
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div >
            </section >

        </>
    )
}
