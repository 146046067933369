import React from 'react';

const Pagination = ({ currentPage, setCurrentPage, pageSize, setPageSize, pages }) => {

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
  };

  const renderPageNumbers = () => {
    return (
      <>
        {Array.from({ length: pages }, (_, index) => index + 1).map(page => (
          <li
            key={page}
            className={`px-5 py-3 br1 rounded-5 d-center ${currentPage === page ? 'active' : ''}`}
            onClick={() => handlePageChange(page)}
          >
            <span className="p1-color fs-five">{page}</span>
          </li>
        ))}
      </>
    );
  };

  const renderPageSizeOptions = () => {
    const pageSizes = [5, 10, 20]; // Example page size options
    return (
      <>
        {pageSizes.map(size => (
          <option key={size} value={size}>{size}</option>
        ))}
      </>
    );
  };

  return (
    <div className="pools_table__pagination mt-6 mt-md-8 d-flex justify-content-center">
      <nav>
        <ul className="d-flex align-items-center gap-3 gap-md-5">
          {/* <li className="px-5 py-3 br1 rounded-5 d-center">
            <select value={pageSize} onChange={(e) => handlePageSizeChange(Number(e.target.value))}>
              {renderPageSizeOptions()}
            </select>
          </li> */}
          {renderPageNumbers()}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
