import React from "react";

export default function Hero() {
  return (
    <>
      <section
        className="hero_area pt-120 pb-16 position-relative"
        style={{ backgroundColor: "#ffb300" }}
      >
        <div className="container  z-1 d-flex justify-content-center align-items-center">
          <div className="row r mt-8 mt-sm-13 mt-md-0 align-items-center justify-content-center">
            <div className="col-xl-10 text-center">
              <div className=" pt-17 pt-sm-20 pt-lg-0">
                <h1 className="display-three mb-5 mb-md-6 wow fadeInUp text-white">
                  LikeMe Coin (LMC) Presale Staking
                </h1>
                <p className="mb-8 mb-md-10 wow fadeInUp ">
                  LMC is native currency in LikeME which is used as a token for
                  rewards distributions, incentives , gift purchases & other
                  in-app utilities.{" "}
                </p>
                <div className="d-flex align-items-center justify-content-center  flex-wrap gap-4 gap-md-6 mb-10 mb-md-13 wow fadeInUp">
                  <a
                    className="hero_area__content-btnone cmn-btn-2 px-6 px-md-8 py-3 d-start gap-3 text-white"
                    href="#Preesale"
                  >
                   Join Presale
                  </a>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
    </>
  );
}
