import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BuyBox from "./Components/BuyBox";
import Dashboard from "./Components/Dashboard";

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/" element={<BuyBox />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;
