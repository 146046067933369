import React from "react";

export default function HowtoJoin() {
  return (
    <>
      <section className="cyber_arena pt-120 pb-120 bg-black-new">
        <div className="container pt-17 pt-sm-20 pt-lg-0">
          <div className="row">
            <div className="cyber_arena__decs mb-8 mb-md-10">
              <h3 className="mb-5 mb-md-6 wow fadeInUp">How to Participate</h3>
              <div className="cyber_arena__decs-card p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                  <h4>1. Connect Wallet</h4>
                </div>
                <p className="mb-6 mb-md-8">
                  Connect BNB Chain (BEP-20) wallet with USDT balance to carry
                  out purchase.
                </p>
              </div>
              <div className="cyber_arena__decs-card-1 p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                  <h4>2. Enter Amount</h4>
                </div>
                <p className="mb-6 mb-md-8">
                  {" "}
                  Enter the amount of LMC you want to purchase.
                </p>
              </div>
              <div className="cyber_arena__decs-card-2 p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                  <h4>3. Confirm & Pay USDT</h4>
                </div>
                <p className="mb-6 mb-md-8">
                  Confirm the transaction by approving USDT from your wallet.
                </p>
              </div>
              <div className="cyber_arena__decs-card-3 p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                  <h4>4. Receive LMC </h4>
                </div>
                <p className="mb-6 mb-md-8">
                  {" "}
                  On completing the transaction, you will be able to LMC balance
                  on the user dashboard.
                </p>
              </div>
              <div className="cyber_arena__decs-card-4 p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                  <h4>5. What to do with LMC</h4>
                </div>
                <p className="mb-6 mb-md-8">
                  {" "}
                  LMC could be used when referral & staking program goes live
                  after staking presale.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
