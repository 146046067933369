import React from "react";

export default function Table() {
  return (
    <div>
      <section className="rewards_faq pt-120 pb-120 position-relative bg-black-new">
        <div className="container">
          <div className="row align-items-center justify-content-center gy-8">
            <div className="col-xl-8">
            <h2 class="mb-5 mb-md-6 wow fadeInUp text-center">Presale Schedule
            </h2>
            <table style={{width:"100%"}}>
  <tr>
    <td className="text-center" style={{width:"100%"}}>TOTAL PRESALE AMOUNT - 86,904.76 LMC
</td>
  
  </tr>
  <tr className="d-flex justify-content-between text-center">
    <th style={{width:"25%"}}>PHASE</th>
    <th style={{width:"25%"}}>RATE</th>
    <th style={{width:"25%"}}>LMC QUANTITY</th>
    <th style={{width:"25%"}}>TIME</th>
  </tr>
  <tr className="d-flex justify-content-between text-center">
    <td style={{width:"25%"}}>1</td>
    <td style={{width:"25%"}}>$3.0</td>
    <td style={{width:"25%"}}>33,333.33</td>
    <td style={{width:"25%"}}>15 DAYS</td>
  </tr>
   <tr className="d-flex justify-content-between text-center">
    <td style={{width:"25%"}}>2</td>
    <td style={{width:"25%"}}>$3.5</td>
    <td style={{width:"25%"}}>28,571.43</td>
    <td style={{width:"25%"}}>15 DAYS</td>
  </tr>
  <tr className="d-flex justify-content-between text-center">
    <td style={{width:"25%"}}>3</td>
    <td style={{width:"25%"}}>$4.0</td>
    <td style={{width:"25%"}}>25,000.00 </td>
    <td style={{width:"25%"}}>15 DAYS</td>
  </tr>
 
</table>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
